@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}

body {
  font-family: "Montserrat";
}

.jseditor,
.output-box,
.error-box {
  font-family: "Courier Prime";
  font-size: 16px;
}
.output-box {
  color: #2d2d2d;
}

.output-box.dark {
  color: #aaaaaa;
}
.app {
  width: 80vw;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 1rem;
  /* position: relative; */
  margin-top: 140px;
}

.wrapper {
  display: flex;
  padding: 1rem;
  /* transition: all .2s; */
}

.wrapper-focus {
  border: 1px solid #66bb6a;
  border-left-width: 4px;
}

.wrapper-focus.dark {
  border: 1px solid #92ff00;
  border-left-width: 4px;
}

.wrapper-blur {
  border: 1px solid #42a5f5;
  border-left-width: 4px;
}

.wrapper-blur.dark {
  border: 1px solid #5dcbe5;
  border-left-width: 4px;
}

.wrapper-cell-unselected {
  border: 1px solid transparent;
  border-left-width: 4px;
}

.editor {
  flex: 1;
}
.label {
  margin: 0rem 1rem;
  font-size: 14px;
  font-weight: 800;
}

.btn {
  outline: none;
  border: none;
  padding: 0.5rem 0.7rem;
  margin: 0.5rem 0rem;
  border-radius: 2px;
}

.btn-success {
  color: white;
  background: linear-gradient(to right bottom, #56ab2f, #72a533);
}

.output-box,
.error-box {
  white-space: pre-wrap;
  margin: 0.3rem 0rem;
}

.error-box {
  color: red;
}

.error-box.dark {
  color: #ff0072;
}

.cell {
  margin-bottom: 1rem;
}

.divider {
  border-bottom: 1.5px solid #e9e9e9;
  display: block;
}

.divider.dark {
  border-bottom: 1.5px solid #aaaa;
  display: block;
}

.header {
  height: 150px;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: white;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.container {
  max-width: 80vw;
  margin: 0 auto;
  height: 100%;
}

.header .container .logo {
  width: 100px;
  margin: 1rem;
}

.header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-wrapper .options {
  flex: 1;
}

.icon-options {
  display: flex;
}

.icon-options button {
  background-color: transparent;
  border: none;
  padding: 6px 8px;
  border: 1px solid #cecece;
  border-radius: 2px;
  font-family: inherit;
  cursor: pointer;
}
.icon-options button svg {
  vertical-align: middle;
}

.icon-options .run,
.icon-options .edit-group,
.icon-options .move-group,
.icon-options .creation-group {
  margin-left: 0.5rem;
}

.edit-group,
.move-group,
.creation-group {
  border: 1px solid #cecece;
  border-radius: 2px;
}

.edit-group button,
.move-group button,
.creation-group button {
  border: none;
  /* border-right: 1px solid #cecece; */
}

.edit-group button:last-child,
.move-group button:last-child,
.creation-group button:last-child {
  border-right: none;
}

.icon-options .run span {
  margin-left: 0.5rem;
  display: inline-block;
  /* vertical-align: middle; */
}

.icon-options button.run {
  display: flex;
  align-items: center;
  justify-content: center;
}

.font-customizer select,
.font-customizer input[type="number"] {
  font-family: inherit;
  padding: 0.2rem 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}
.font-customizer {
  margin-top: 0.5rem;
}
.font-customizer label {
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0rem 0.5rem;
}

.font-customizer label:first-child {
  margin-left: 0;
}

/* Dark Theme Modifiers */
#root.dark {
  background-color: #393939;
}

.app.dark {
  color: #aaaaaa;
  background-color: #393939;
}

.header.dark {
  background: #242424;
  color: #aaaaaa;
}

.font-customizer select.dark,
.font-customizer input[type="number"].dark {
  background: #393939;
  outline: none;
  border: transparent;
  color: #aaaaaa;
}

.jupyter-app {
  transition: all 0.5s;
}

.jupyter-app.dark {
  background-color: #1a1a1a;
}
